/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import Vue from 'vue'
// import router from 'vue-router'

// axios
import axios from 'axios'
// import createAuthRefreshInterceptor from 'axios-auth-refresh'

import store from '@/store/index'
// import router from '@/router'

// import router from '@/router/index'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 1600000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

const currentExecutingRequests = {}

Vue.prototype.$http.interceptors.request.use(
  config => {
    if (currentExecutingRequests[config.url]) {
      const source = currentExecutingRequests[config.url]
      delete currentExecutingRequests[config.url]
      source.cancel()
    }

    store.commit('appConfig/UPDATE_LOADER', true)
    const accessToken = store.state.auth.token
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers = { Authorization: `Bearer ${accessToken}`, ...config.headers }
    }

    const cancelToken = axios.CancelToken
    const source = cancelToken.source()
    // eslint-disable-next-line no-param-reassign
    config.cancelToken = source.token
    currentExecutingRequests[config.url] = source

    return config
  },
  error => Promise.reject(error),
)

const retryOriginalRequest = (milliseconds, originalRequest) => new Promise(resolve => {
  setTimeout(() => resolve(axiosIns(originalRequest)), milliseconds)
})

// let retried = false
// eslint-disable-next-line arrow-body-style
axiosIns.interceptors.response.use(response => {
  store.commit('appConfig/UPDATE_LOADER', false)
  if (currentExecutingRequests[response.config.url]) {
    delete currentExecutingRequests[response.config.url]
  }
  return Promise.resolve(response)
}, async error => {
  if (axios.isCancel(error)) {
    return new Promise(() => {})
  }
  const { config, response: { status } } = error
  const originalRequest = config

  store.commit('appConfig/UPDATE_LOADER', false)

  if (status === 401) {
    if (originalRequest.url !== 'refresh-token' && !originalRequest._retry) {
      originalRequest._retry = true
      await store
        .dispatch('auth/refreshToken')
        .then(res => {
          originalRequest.headers.Authorization = `Bearer ${res.data.accessToken}`
        })
      return retryOriginalRequest(1000, originalRequest)
    }
    store
      .dispatch('auth/unauthorizedLogout')
    location.href = '/login'
    return Promise.reject(error.response)
  }

  if (currentExecutingRequests[originalRequest.url]) {
    delete currentExecutingRequests[originalRequest.url]
  }

  return Promise.reject(error.response)
})

export default axiosIns
