import Vue from 'vue'
import moment from 'moment'

const timezone = process.env.VUE_APP_TIME_ZONE

function numToWords(num = 0) {
  if (num === 0) return 'Zero'
  // eslint-disable-next-line no-param-reassign, no-mixed-operators
  num = ('0'.repeat(2 * (num += '').length % 3) + num).match(/.{3}/g)
  let out = ''
  const T10s = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
  const T20s = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
  const sclT = ['', 'Thousand', 'Million', 'Billion', 'Trillion', 'Quadrillion']
  return num.forEach((n, i) => {
    if (+n) {
      const hund = +n[0]; const ten = +n.substring(1); const
        scl = sclT[num.length - i - 1]
      out += (out ? ' ' : '') + (hund ? `${T10s[hund]} Hundred` : '') + (hund && ten ? ' ' : '') + (ten < 20 ? T10s[ten] : T20s[+n[1]] + (+n[2] ? ' ' : '') + T10s[+n[2]])
      out += (out && scl ? ' ' : '') + scl
    }
  // eslint-disable-next-line no-sequences
  }), out
}

function isValidPhoneNumber(number) {
  // Regular expression to check if the number is exactly 8 digits
  const eightDigitPattern = /^\d{8}$/
  // Regular expression to check if the number starts with +65 or +60 and is followed by 8 digits
  const plus65Pattern = /^\+65\d{8}$/
  const plus60Pattern = /^\+60\d{8}$/
  // Regular expression to check if the number starts with +65 or +60, followed by a space, and then 8 digits
  const plus65WithSpacePattern = /^\+65 \d{8}$/
  const plus60WithSpacePattern = /^\+60 \d{8}$/

  // Check if the number matches any of the patterns
  if (eightDigitPattern.test(number) || plus65Pattern.test(number) || plus60Pattern.test(number)
      || plus65WithSpacePattern.test(number) || plus60WithSpacePattern.test(number)) {
    return true
  }
  return false
}

Vue.mixin({
  methods: {
    dateFormatWithTime: date => moment(date).utcOffset(timezone).format('DD/MM/YYYY, hh:mma'),
    dateFormat: date => moment(date).utcOffset(timezone).format('DD/MM/YYYY'),
    dateFormatFromTimeString: time => moment(time, 'HH:mm').utcOffset(timezone).format('hh:mma'),
    dateFormatFromTimeStringWithoutTimezone: time => moment(time, 'HH:mm').format('hh:mma'),
    dateFormatFromTimeStringWithoutTimezoneMandarin: time => moment(time, 'HH:mm').locale('zh_cn').format('hh:mma'),
    dateFormatWithoutTimezoneMandarin: date => moment(date, 'DD/MM/YYYY').locale('zh_cn').format('LL'),
    timeFormatFromDateString: date => moment(date).utcOffset(timezone).format('hh:mma'),
    twentyFourHourTimeFormatFromDateString: date => moment(date).utcOffset(timezone).format('HH:mm'),
    dateFormatWithTwentyFourHourTimeFormatFromDateString: date => moment(date).utcOffset(timezone).format('DD/MM/YYYY, HH:mm'),
    standardDateFormatWithTwentyFourHourTimeFormatFromDateString: date => moment(date).utcOffset(timezone).format('DD/MM/YYYY, H:i'),
    standardDateFormat: date => moment(date).utcOffset(timezone).format('YYYY-MM-DD'),
    fullMonthDateFormat: date => moment(date, 'DD/MM/YYYY').utcOffset(timezone).format('DD MMMM YYYY'),
    dateFormatWithDay: date => moment(date, 'DD/MM/YYYY').utcOffset(timezone).format('dddd, MMMM DD, YYYY'),
    dateFormatWithShortDay: date => moment(date).utcOffset(timezone).format('ddd DD/MM/YYYY hh:mma'),
    dateFormatWithFormatParam: (date, format) => moment(date).utcOffset(timezone).format(format),
    humanReadableFormat: date => moment(date).utcOffset(timezone).fromNow(),
    // this does not work in safari becasue of lookbehind support
    // numberFormat: number => (number ? parseFloat(number).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : '0.00'),
    numberFormat: number => {
      if (number) {
        const parts = parseFloat(number).toFixed(2).split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return parts.join('.')
      }
      return '0.00'
    },
    floatNumber: number => {
      if (number) {
        return parseFloat(number).toFixed(2)
      }
      return '0.00'
    },
    isMobile: () => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      }
      return false
    },
    addAlpha: (color, opacity) => {
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
      return color + _opacity.toString(16).toUpperCase()
    },
    numberToWord: number => {
      const nums = number.toString().split('.')
      const whole = numToWords(Number(nums[0]))
      if (nums.length === 2 && Number(nums[1]) > 0) {
        const fraction = numToWords(Number(nums[1]))
        return `${whole} point ${fraction}`
      }
      return whole
    },
    modifyContact: contact => {
      const modifiedContact = ''
      if (contact) {
        if (!Array.isArray(contact)) {
          if (isValidPhoneNumber(contact)) {
            // If the number is exactly 8 digits, add +65 and a space
            if (/^\d{8}$/.test(contact)) {
              return `+65 ${contact}`
            }
            // If the number starts with +65 and is followed by 8 digits without space, add the space
            if (/^\+65\d{8}$/.test(contact)) {
              return `+65 ${contact.slice(3)}`
            }
            // If the number starts with +60 and is followed by 8 digits without space, add the space
            if (/^\+60\d{8}$/.test(contact)) {
              return `+60 ${contact.slice(3)}`
            }
            // If the number is already in the correct format, return it as is
            if (/^\+65 \d{8}$/.test(contact) || /^\+60 \d{8}$/.test(contact)) {
              return contact
            }
          }
        }
      }

      return modifiedContact
    },
  },
})
