export default [
  {
    path: '/purchasing/purchase-requests/:type',
    name: 'purchasing-purchase-requests-index',
    component: () => import('@/views/purchase/purchase-request/PurchaseRequestList.vue'),
    meta: {
      title: 'Purchase Requests',
      pageTitle: 'Purchasing',
      breadcrumb: [
        {
          text: 'Purchase Requests',
          active: true,
        },
      ],
      resource: 'PurchaseRequest',
      action: 'read',
    },
  },
  {
    path: '/purchasing/purchase-requests/:id/show',
    name: 'purchasing-purchase-request-show',
    component: () => import('@/views/purchase/purchase-request/PurchaseRequestDetail.vue'),
    meta: {
      title: 'Purchase Request Detail',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-purchase-requests-index',
      breadcrumb: [
        {
          text: 'Purchase Requests',
          to: '/purchasing/purchase-requests/all-requests',
        },
        {
          text: 'Purchase Request Detail',
          active: true,
        },
      ],
      resource: ['PurchaseRequest', 'MyPurchaseRequest', 'MyPurchaseRequest'],
      action: ['show', 'my-request-show', 'my-department-request-view'],
    },
  },
  {
    path: '/purchasing/purchase-requests/new/create',
    name: 'purchasing-purchase-requests-create',
    component: () => import('@/views/purchase/purchase-request/PurchaseRequestCreate.vue'),
    meta: {
      title: 'Create New Purchase Request',
      pageTitle: 'Purchasing',
      // navActiveLink: 'purchasing-purchase-request-index',
      breadcrumb: [
        {
          text: 'My Purchase Requests',
          to: '/purchasing/my-purchase-requests/all-requests',
        },
        {
          text: 'Creating New Purchase Request',
          active: true,
        },
      ],
      resource: ['PurchaseRequest', 'MyPurchaseRequest'],
      action: ['create', 'my-request-create'],
    },
  },
  {
    path: '/purchasing/purchase-requests/:id/edit',
    name: 'purchasing-purchase-requests-edit',
    component: () => import('@/views/purchase/purchase-request/PurchaseRequestEdit.vue'),
    meta: {
      title: 'Edit Purchase Request',
      pageTitle: 'Purchasing',
      // navActiveLink: 'purchasing-purchase-request-index',
      breadcrumb: [
        {
          text: 'Purchase Requests',
          to: '/purchasing/purchase-requests/all-requests',
        },
        {
          text: 'Edit Purchase Request',
          active: true,
        },
      ],
      resource: ['PurchaseRequest', 'MyPurchaseRequest', 'MyPurchaseRequest'],
      action: ['update', 'my-request-update', 'my-department-request-edit'],
    },
  },
  // {
  //   path: '/purchasing/purchase-requests/:id/preview',
  //   name: 'purchasing-purchase-requests-preview',
  //   component: () => import('@/views/purchase/purchase-request/PurchaseRequestFinalSubmit.vue'),
  //   meta: {
  //     title: 'Preview New Purchase Request',
  //     pageTitle: 'Purchasing',
  //     // navActiveLink: 'purchasing-purchase-request-index',
  //     breadcrumb: [
  //       {
  //         text: 'Purchase Requests',
  //         to: '/purchasing/purchase-requests/all-requests',
  //       },
  //       {
  //         text: 'Preview New Purchase Request',
  //         active: true,
  //       },
  //     ],
  //     resource: 'PurchaseRequest',
  //     action: 'update',
  //   },
  // },
  {
    path: '/purchasing/purchase-requests/prf/:id/preview',
    name: 'purchasing-purchase-requests-prf-preview',
    component: () => import('@/views/purchase/purchase-request/PreviewRequest.vue'),
    meta: {
      title: 'Purchase Request Preview',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-purchase-requests-index',
      breadcrumb: [
        {
          text: 'Purchase Requests',
          to: '/purchasing/purchase-requests/all-requests',
        },
        {
          text: 'Review Purchase Request',
          active: true,
        },
      ],
      resource: ['PurchaseRequest', 'MyPurchaseRequest', 'MyPurchaseRequest', 'MyPurchaseRequest', 'MyPurchaseRequest'],
      action: ['show', 'my-request-show', 'my-department-request-view', 'my-request-update', 'my-department-request-edit'],
    },
  },
  {
    path: '/purchasing/purchase-requests/qcf/:id/preview',
    name: 'purchasing-purchase-requests-qcf-preview',
    component: () => import('@/views/purchase/quotations/PreviewQuotation.vue'),
    meta: {
      title: 'Quotation Preview',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-purchase-requests-index',
      breadcrumb: [
        {
          text: 'Purchase Requests',
          to: '/purchasing/purchase-requests/all-requests',
        },
        {
          text: 'Preview QCF',
          active: true,
        },
      ],
      resource: ['Quotation', 'PurchaseRequest', 'MyPurchaseRequest', 'MyPurchaseRequest', 'MyPurchaseRequest', 'MyPurchaseRequest'],
      action: ['show', 'show', 'my-request-show', 'my-department-request-view', 'my-request-update', 'my-department-request-edit'],
    },
  },
  {
    path: '/purchasing/purchase-requests/po/:id/preview',
    name: 'purchasing-purchase-requests-po-preview',
    component: () => import('@/views/purchase/purchase-order/PreviewOrder.vue'),
    meta: {
      title: 'Purchase Order Preview',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-purchase-requests-index',
      breadcrumb: [
        {
          text: 'Purchase Requests',
          to: '/purchasing/purchase-requests/all-requests',
        },
        {
          text: 'Preview PO',
          active: true,
        },
      ],
      resource: ['PurchaseOrder', 'PurchaseRequest', 'MyPurchaseRequest', 'MyPurchaseRequest', 'MyPurchaseRequest', 'MyPurchaseRequest'],
      action: ['show', 'show', 'my-request-show', 'my-department-request-view', 'my-request-update', 'my-department-request-edit'],
    },
  },
  // {
  //   path: '/purchasing/purchase-request-item/:type',
  //   name: 'purchasing-purchase-request-item-index',
  //   component: () => import('@/views/purchase/purchase-request-item/PurchaseRequestItemList.vue'),
  //   meta: {
  //     title: '',
  //     pageTitle: 'Purchase Requests',
  //     breadcrumb: [
  //       {
  //         text: 'Service Request Items',
  //         active: true,
  //       },
  //     ],
  //     resource: 'PurchaseRequest',
  //     action: 'read',
  //   },
  // },
  {
    path: '/purchasing/my-purchase-requests/:type',
    name: 'purchasing-my-purchase-requests-index',
    component: () => import('@/views/purchase/my-purchase-request/PurchaseRequestList.vue'),
    meta: {
      title: 'My Purchase Requests',
      pageTitle: 'Purchasing',
      breadcrumb: [
        {
          text: 'My Purchase Requests',
          active: true,
        },
      ],
      resource: ['MyPurchaseRequest', 'MyPurchaseRequest'],
      action: ['my-request-read', 'my-department-request-read'],
    },
  },
  {
    path: '/purchasing/vendors/:type',
    name: 'purchasing-vendors-index',
    component: () => import('@/views/purchase/vendors/VendorList.vue'),
    meta: {
      title: 'Vendors',
      pageTitle: 'Purchasing',
      breadcrumb: [
        {
          text: 'Vendors',
          active: true,
        },
      ],
      resource: 'Vendor',
      action: 'read',
    },
  },
  {
    path: '/purchasing/vendors/:id/show',
    name: 'purchasing-vendors-show',
    component: () => import('@/views/purchase/vendors/VendorDetail.vue'),
    meta: {
      title: 'Vendor Detail',
      pageTitle: 'Purchasing',
      breadcrumb: [
        {
          text: 'Vendors',
          to: '/purchasing/vendors/all-vendors',
        },
        {
          text: 'Vendor Detail',
          active: true,
        },
      ],
      resource: 'Vendor',
      action: 'show',
    },
  },
  {
    path: '/purchasing/vendors/:id/approve',
    name: 'purchasing-vendors-approve',
    component: () => import('@/views/purchase/vendors/ApproveVendor.vue'),
    meta: {
      title: 'Approve Vendor',
      pageTitle: 'Purchasing',
      breadcrumb: [
        {
          text: 'Vendors',
          to: '/purchasing/vendors/all-vendors',
        },
        {
          text: 'Approve New Vendor Registration',
          active: true,
        },
      ],
      resource: 'Vendor',
      action: 'show',
    },
  },
  {
    path: '/purchasing/vendors/new/create',
    name: 'purchasing-vendors-create',
    component: () => import('@/views/purchase/vendors/VendorCreate.vue'),
    meta: {
      title: 'New Vendor Registration',
      pageTitle: 'Purchasing',
      // navActiveLink: 'purchasing-vendors-index',
      breadcrumb: [
        {
          text: 'Vendors',
          to: '/purchasing/vendors/all-vendors',
        },
        {
          text: 'New Vendor Registration Form',
          active: true,
        },
      ],
      resource: 'Vendor',
      action: 'create',
    },
  },
  {
    path: '/purchasing/vendors/:id/edit',
    name: 'purchasing-vendors-edit',
    component: () => import('@/views/purchase/vendors/VendorEdit.vue'),
    meta: {
      title: 'Edit Vendor',
      pageTitle: 'Purchasing',
      // navActiveLink: 'purchasing-vendors-index',
      breadcrumb: [
        {
          text: 'Vendors',
          to: '/purchasing/vendors/all-vendors',
        },
        {
          text: 'Edit Vendor',
          active: true,
        },
      ],
      resource: 'Vendor',
      action: 'update',
    },
  },
  {
    path: '/purchasing/vendors/self-registered/:id/edit',
    name: 'purchasing-self-registered-vendors-edit',
    component: () => import('@/views/purchase/vendors/SelfRegisteredVendorEdit.vue'),
    meta: {
      title: 'Edit Vendor',
      pageTitle: 'Purchasing',
      // navActiveLink: 'purchasing-vendors-index',
      breadcrumb: [
        {
          text: 'Vendors',
          to: '/purchasing/vendors/all-vendors',
        },
        {
          text: 'Edit Vendor',
          active: true,
        },
      ],
      resource: 'Vendor',
      action: 'update',
    },
  },
  {
    path: '/purchasing/vendors/:id/preview',
    name: 'purchasing-vendors-preview',
    component: () => import('@/views/purchase/vendors/VendorPreview.vue'),
    meta: {
      title: 'Preview New Vendor Registration',
      pageTitle: 'Purchasing',
      // navActiveLink: 'purchasing-vendors-index',
      breadcrumb: [
        {
          text: 'Vendors',
          to: '/purchasing/vendors/all-vendors',
        },
        {
          text: 'Preview New Vendor Registration Form',
          active: true,
        },
      ],
      resource: 'Vendor',
      action: 'update',
    },
  },
  {
    path: '/purchasing/vendors/evaluations/list',
    name: 'purchasing-vendors-evaluations',
    component: () => import('@/views/purchase/evaluation/EvaluationList.vue'),
    meta: {
      title: 'Vendor Evaluations & Rankings',
      pageTitle: 'Purchasing',
      // navActiveLink: 'purchasing-vendors-index',
      breadcrumb: [
        {
          text: 'Vendors',
          to: '/purchasing/vendors/all-vendors',
        },
        {
          text: 'Vendor Evaluations & Rankings',
          active: true,
        },
      ],
      resource: 'Vendor',
      action: 'generate-evaluation',
    },
  },
  {
    path: '/purchasing/vendors/evaluation/generate',
    name: 'purchasing-vendors-generate-evaluation',
    component: () => import('@/views/purchase/evaluation/generate-evaluation/VendorSelectionForEvaluation.vue'),
    meta: {
      title: 'Generate Vendor Evaluation',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-vendors-index',
      breadcrumb: [
        {
          text: 'Vendors',
          to: '/purchasing/vendors/all-vendors',
        },
        {
          text: 'Generate Evaluation Report',
          active: true,
        },
      ],
      resource: 'Vendor',
      action: 'generate-evaluation',
    },
  },
  {
    path: '/purchasing/vendors/evaluation/generate/scoring',
    name: 'purchasing-vendors-generate-evaluation-scoring',
    component: () => import('@/views/purchase/evaluation/generate-evaluation/EvaluationForm.vue'),
    meta: {
      title: 'Generate Vendor Evaluation',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-vendors-index',
      breadcrumb: [
        {
          text: 'Vendors',
          to: '/purchasing/vendors/all-vendors',
        },
        {
          text: 'Generate Evaluation Report',
          to: '/purchasing/vendors/evaluation/generate',
        },
        {
          text: 'Annual Vendor Evaluation - Scoring',
          active: true,
        },
      ],
      resource: 'Vendor',
      action: 'generate-evaluation',
    },
  },
  {
    path: '/purchasing/vendors/evaluation/:id/show',
    name: 'purchasing-vendors-evaluation-detail',
    component: () => import('@/views/purchase/evaluation/EvaluationDetail.vue'),
    meta: {
      title: 'Vendor Evaluation Detail',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-vendors-index',
      breadcrumb: [
        {
          text: 'Vendors',
          to: '/purchasing/vendors/all-vendors',
        },
        {
          text: 'Annual Vendor Evaluation - Scoring',
          to: '/purchasing/vendors/evaluations/list',
        },
        {
          text: 'Evaluation Detail',
          active: true,
        },
      ],
      resource: 'Vendor',
      action: 'evaluation-view',
    },
  },
  {
    path: '/purchasing/vendors/evaluation/:id/preview',
    name: 'purchasing-vendors-evaluation-preview',
    component: () => import('@/views/purchase/evaluation/EvaluationPreview.vue'),
    meta: {
      title: 'Preview Annual Vendor Evaluation',
      pageTitle: 'Purchasing',
      // navActiveLink: 'purchasing-vendors-index',
      breadcrumb: [
        {
          text: 'Vendor Evaluations',
          to: '/purchasing/vendors/evaluations/list',
        },
        {
          text: 'Preview Annual Vendor Evaluation',
          active: true,
        },
      ],
      resource: 'Vendor',
      action: 'evaluation-view',
    },
  },
  {
    path: '/purchasing/vendors/evaluation/:id/edit',
    name: 'purchasing-vendors-evaluation-update',
    component: () => import('@/views/purchase/evaluation/EvaluationEdit.vue'),
    meta: {
      title: 'Update Annual Vendor Evaluation',
      pageTitle: 'Purchasing',
      // navActiveLink: 'purchasing-vendors-index',
      breadcrumb: [
        {
          text: 'Vendor Evaluations',
          to: '/purchasing/vendors/evaluations/list',
        },
        {
          text: 'Update Annual Vendor Evaluation',
          active: true,
        },
      ],
      resource: 'Vendor',
      action: 'generate-evaluation',
    },
  },
  {
    path: '/purchasing/items-master',
    name: 'purchasing-items-master-index',
    component: () => import('@/views/purchase/items-master/ItemsMasterList.vue'),
    meta: {
      title: 'Items Master',
      pageTitle: 'Purchasing',
      breadcrumb: [
        {
          text: 'Items Master',
          active: true,
        },
      ],
      resource: 'ItemsMaster',
      action: 'read',
    },
  },
  {
    path: '/purchasing/items-master/:id/show',
    name: 'purchasing-items-master-show',
    component: () => import('@/views/purchase/items-master/ItemsMasterDetail.vue'),
    meta: {
      title: 'Items Master Detail',
      pageTitle: 'Purchasing',
      breadcrumb: [
        {
          text: 'Items Master',
          to: '/purchasing/items-master',
        },
        {
          text: 'Items Master Detail',
          active: true,
        },
      ],
      resource: 'ItemsMaster',
      action: 'show',
    },
  },
  {
    path: '/purchasing/items-master/create',
    name: 'purchasing-items-master-create',
    component: () => import('@/views/purchase/items-master/ItemsMasterCreate.vue'),
    meta: {
      title: 'Create New Items Master',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-items-master-index',
      breadcrumb: [
        {
          text: 'Items Master',
          to: '/purchasing/items-master',
        },
        {
          text: 'Create New Items Master',
          active: true,
        },
      ],
      resource: 'ItemsMaster',
      action: 'create',
    },
  },
  {
    path: '/purchasing/items-master/:id/edit',
    name: 'purchasing-items-master-edit',
    component: () => import('@/views/purchase/items-master/ItemsMasterEdit.vue'),
    meta: {
      title: 'Edit Items Master',
      pageTitle: 'Purchasing',
      breadcrumb: [
        {
          text: 'Items Master',
          to: '/purchasing/items-master',
        },
        {
          text: 'Edit Items Master',
          active: true,
        },
      ],
      resource: 'ItemsMaster',
      action: 'update',
    },
  },
  {
    path: '/purchasing/purchase-requests/:id/quotations/create',
    name: 'purchasing-quotations-create',
    component: () => import('@/views/purchase/quotations/QuotationCreate.vue'),
    meta: {
      title: 'Create New Quotation',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-purchase-requests-index',
      breadcrumb: [
        {
          text: 'Purchase Requests',
          to: '/purchasing/purchase-requests/all-requests',
        },
        {
          text: 'Create QCF',
          active: true,
        },
      ],
      resource: 'Quotation',
      action: 'create',
    },
  },
  {
    path: '/purchasing/purchase-requests/:parent/quotations/:id/update',
    name: 'purchasing-quotations-edit',
    component: () => import('@/views/purchase/quotations/QuotationEdit.vue'),
    meta: {
      title: 'Update Quotation',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-purchase-requests-index',
      breadcrumb: [
        {
          text: 'Purchase Requests',
          to: '/purchasing/purchase-requests/all-requests',
        },
        {
          text: 'Update QCF',
          active: true,
        },
      ],
      resource: 'Quotation',
      action: 'update',
    },
  },
  {
    path: '/purchasing/purchase-requests/:id/purchase-order/create',
    name: 'purchasing-purchase-order-create',
    component: () => import('@/views/purchase/purchase-order/CreatePurchaseOrder.vue'),
    meta: {
      title: 'Create New Purchase Order',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-purchase-requests-index',
      breadcrumb: [
        {
          text: 'Purchase Requests',
          to: '/purchasing/purchase-requests/all-requests',
        },
        {
          text: 'Create Purchase Order',
          active: true,
        },
      ],
      resource: 'PurchaseOrder',
      action: 'create',
    },
  },
  {
    path: '/purchasing/purchase-requests/:parent/purchase-order/:id/update',
    name: 'purchasing-purchase-order-edit',
    component: () => import('@/views/purchase/purchase-order/PurchaseOrderEdit.vue'),
    meta: {
      title: 'Update Purchase Order',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-purchase-requests-index',
      breadcrumb: [
        {
          text: 'Purchase Requests',
          to: '/purchasing/purchase-requests/all-requests',
        },
        {
          text: 'Update PO',
          active: true,
        },
      ],
      resource: 'PurchaseOrder',
      action: 'update',
    },
  },
  {
    path: '/purchasing/delivery-receipts/:type',
    name: 'purchasing-delivery-receipts-index',
    component: () => import('@/views/purchase/delivery-receipts/DeliveryReceiptsList.vue'),
    meta: {
      title: 'Delivery Receipts',
      pageTitle: 'Purchasing',
      breadcrumb: [
        {
          text: 'Delivery Receipts',
          active: true,
        },
      ],
      resource: 'DeliveryReceipt',
      action: 'read',
    },
  },
  {
    path: '/purchasing/my-delivery-receipts/:type',
    name: 'purchasing-my-delivery-receipts-index',
    component: () => import('@/views/purchase/delivery-receipts/MyDeliveryReceiptsList.vue'),
    meta: {
      title: 'My Delivery Receipts',
      pageTitle: 'Purchasing',
      breadcrumb: [
        {
          text: 'My Delivery Receipts',
          active: true,
        },
      ],
      resource: ['DeliveryReceipt', 'DeliveryReceipt'],
      action: ['my-delivery-receipt-read', 'my-department-delivery-receipt-read'],
    },
  },
  {
    path: '/purchasing/delivery-receipts/:id/show',
    name: 'purchasing-delivery-receipt-show',
    component: () => import('@/views/purchase/delivery-receipts/DeliveryReceiptDetail.vue'),
    meta: {
      title: 'Delivery Receipt Detail',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-delivery-receipts-index',
      breadcrumb: [
        {
          text: 'Delivery Receipts',
          to: '/purchasing/delivery-receipts/all-receipts',
        },
        {
          text: 'Delivery Receipt Detail',
          active: true,
        },
      ],
      resource: 'DeliveryReceipt',
      action: 'show',
    },
  },
  {
    path: '/purchasing/delivery-receipts/new/create',
    name: 'purchasing-delivery-receipts-create',
    component: () => import('@/views/purchase/delivery-receipts/DeliveryReceiptCreate.vue'),
    meta: {
      title: 'Create Delivery Receipt',
      pageTitle: 'Purchasing',
      // navActiveLink: 'purchasing-delivery-receipts-index',
      breadcrumb: [
        {
          text: 'Delivery Receipts',
          to: '/purchasing/delivery-receipts/all-receipts',
        },
        {
          text: 'Creating Delivery Receipt',
          active: true,
        },
      ],
      resource: 'DeliveryReceipt',
      action: 'create',
    },
  },
  {
    path: '/purchasing/delivery-receipts/:id/edit',
    name: 'purchasing-delivery-receipts-edit',
    component: () => import('@/views/purchase/delivery-receipts/DeliveryReceiptEdit.vue'),
    meta: {
      title: 'Edit Delivery Receipt',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-delivery-receipts-index',
      breadcrumb: [
        {
          text: 'Delivery Receipts',
          to: '/purchasing/delivery-receipts/all-receipts',
        },
        {
          text: 'Editing Delivery Receipt',
          active: true,
        },
      ],
      resource: ['DeliveryReceipt', 'DeliveryReceipt'],
      action: ['update', 'my-department-delivery-receipt-update'],
    },
  },
  {
    path: '/purchasing/delivery-receipts/:id/link/po',
    name: 'purchasing-delivery-receipts-link-to-po',
    component: () => import('@/views/purchase/delivery-receipts/LinkReceiptToPO.vue'),
    meta: {
      title: 'Link Delivery Receipt To PO/PRF',
      pageTitle: 'Purchasing',
      // navActiveLink: 'purchasing-delivery-receipts-index',
      breadcrumb: [
        {
          text: 'Delivery Receipts',
          to: '/purchasing/delivery-receipts/all-receipts',
        },
        {
          text: 'Link Delivery Receipt to PO',
          active: true,
        },
      ],
      resource: 'DeliveryReceipt',
      action: 'link-to-po',
    },
  },
  {
    path: '/purchasing/ncf/:type',
    name: 'purchasing-ncf-index',
    component: () => import('@/views/purchase/ncf/NCFList.vue'),
    meta: {
      title: 'Non-Conformance Forms',
      pageTitle: 'Purchasing',
      breadcrumb: [
        {
          text: 'Non-Conformance Forms',
          active: true,
        },
      ],
      resource: 'NonConformanceForm',
      action: 'read',
    },
  },
  {
    path: '/purchasing/my-ncf/:type',
    name: 'purchasing-my-ncf-index',
    component: () => import('@/views/purchase/ncf/MyNCFList.vue'),
    meta: {
      title: 'My Non-Conformance Forms',
      pageTitle: 'Purchasing',
      breadcrumb: [
        {
          text: 'My Non-Conformance Forms',
          active: true,
        },
      ],
      resource: ['NonConformanceForm', 'NonConformanceForm'],
      action: ['my-ncf-read', 'my-department-ncf-read'],
    },
  },
  {
    path: '/purchasing/ncf/:id/show',
    name: 'purchasing-ncf-show',
    component: () => import('@/views/purchase/ncf/NCFDetail.vue'),
    meta: {
      title: 'Non-Conformance Form Detail',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-ncf-index',
      breadcrumb: [
        {
          text: 'Non-Conformance Forms',
          to: '/purchasing/ncf/all-ncfs',
        },
        {
          text: 'Non-Conformance Form Detail',
          active: true,
        },
      ],
      resource: 'NonConformanceForm',
      action: 'show',
    },
  },
  {
    path: '/purchasing/ncf/new/create',
    name: 'purchasing-ncf-create',
    component: () => import('@/views/purchase/ncf/CreateNCF.vue'),
    meta: {
      title: 'Creating New Non-Conformance Form',
      pageTitle: 'Purchasing',
      // navActiveLink: 'purchasing-ncf-index',
      breadcrumb: [
        {
          text: 'Non-Conformance Forms',
          to: '/purchasing/ncf/all-ncfs',
        },
        {
          text: 'Creating New Non-Conformance Form',
          active: true,
        },
      ],
      resource: 'NonConformanceForm',
      action: 'create',
    },
  },
  {
    path: '/purchasing/ncf/:id/edit',
    name: 'purchasing-ncf-edit',
    component: () => import('@/views/purchase/ncf/EditNCF.vue'),
    meta: {
      title: 'Edit Non-Conformance Form',
      pageTitle: 'Purchasing',
      // navActiveLink: 'purchasing-ncf-index',
      breadcrumb: [
        {
          text: 'Non-Conformance Forms',
          to: '/purchasing/ncf/all-ncfs',
        },
        {
          text: 'Edit Non-Conformance Form',
          active: true,
        },
      ],
      resource: 'NonConformanceForm',
      action: 'update',
    },
  },
  {
    path: '/purchasing/ncf/:id/preview',
    name: 'purchasing-ncf-preview',
    component: () => import('@/views/purchase/ncf/PreviewNCF.vue'),
    meta: {
      title: 'Non-Conformance Form Preview',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-ncf-index',
      breadcrumb: [
        {
          text: 'Non-Conformance Forms',
          to: '/purchasing/ncf/all-ncfs',
        },
        {
          text: 'Preview',
          active: true,
        },
      ],
      resource: 'NonConformanceForm',
      action: 'show',
    },
  },
  {
    path: '/purchasing/purchase-requests/:id/allocate-budget',
    name: 'purchasing-purchase-request-allocate-budget',
    component: () => import('@/views/purchase/purchase-request/CreateBudgetExpense.vue'),
    meta: {
      title: 'Purchase Request Allocate Budget',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-purchase-requests-index',
      breadcrumb: [
        {
          text: 'Purchase Requests',
          to: '/purchasing/purchase-requests/all-requests',
        },
        {
          text: 'Allocate Budget',
          active: true,
        },
      ],
      resource: ['BudgetAllocation', 'BudgetAllocation'],
      action: ['create', 'allocate-budget-all-stages'],
    },
  },
  {
    path: '/purchasing/purchase-requests/:parent/allocate-budget/:id/edit',
    name: 'purchasing-purchase-request-update-allocated-budget',
    component: () => import('@/views/purchase/purchase-request/EditBudgetExpense.vue'),
    meta: {
      title: 'Purchase Request Allocate Budget',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-purchase-requests-index',
      breadcrumb: [
        {
          text: 'Purchase Requests',
          to: '/purchasing/purchase-requests/all-requests',
        },
        {
          text: 'Edit Allocated Budget',
          active: true,
        },
      ],
      resource: ['BudgetAllocation', 'BudgetAllocation'],
      action: ['create', 'edit-budget-all-stages'],
    },
  },
  {
    path: '/purchasing/my-vendor-payments',
    name: 'purchasing-my-vendor-payments-index',
    component: () => import('@/views/purchase/my-vendor-payment/PaymentList.vue'),
    meta: {
      title: 'My Vendor Payments',
      pageTitle: 'Purchasing',
      breadcrumb: [
        {
          text: 'My Vendor Payments',
          active: true,
        },
      ],
      resource: ['MyVendorPayment', 'MyVendorPayment'],
      action: ['my-vp-read', 'my-department-vp-read'],
    },
  },
  {
    path: '/purchasing/vendor-payments',
    name: 'purchasing-vendor-payments-index',
    component: () => import('@/views/purchase/vendor-payment/PaymentList.vue'),
    meta: {
      title: 'Vendor Payments',
      pageTitle: 'Purchasing',
      breadcrumb: [
        {
          text: 'Vendor Payments',
          active: true,
        },
      ],
      resource: 'VendorPayment',
      action: 'read',
    },
  },
  {
    path: '/purchasing/purchase-requests/:id/invoice/create',
    name: 'purchasing-purchase-request-create-invoice',
    component: () => import('@/views/purchase/vendor-payment/CreateInvoice.vue'),
    meta: {
      title: 'Purchase Request Invoice Create',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-purchase-requests-index',
      breadcrumb: [
        {
          text: 'Purchase Requests',
          to: '/purchasing/purchase-requests/all-requests',
        },
        {
          text: 'Create Vendor Payment',
          active: true,
        },
      ],
      resource: ['VendorPayment', 'MyVendorPayment'],
      action: ['create', 'my-vp-create'],
    },
  },
  {
    path: '/purchasing/purchase-requests/:parent/invoice/:id/edit',
    name: 'purchasing-purchase-request-update-invoice',
    component: () => import('@/views/purchase/vendor-payment/EditInvoice.vue'),
    meta: {
      title: 'Purchase Request Invoice Update',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-purchase-requests-index',
      breadcrumb: [
        {
          text: 'Purchase Requests',
          to: '/purchasing/purchase-requests/all-requests',
        },
        {
          text: 'Edit Vendor Payment',
          active: true,
        },
      ],
      resource: 'VendorPayment',
      action: 'update',
    },
  },
  {
    path: '/purchasing/purchase-requests/vendor-payment/:id/preview',
    name: 'purchasing-vendor-payment-preview',
    component: () => import('@/views/purchase/vendor-payment/PreviewInvoice.vue'),
    meta: {
      title: 'Vendor Payment Preview',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-purchase-requests-index',
      breadcrumb: [
        {
          text: 'Purchase Requests',
          to: '/purchasing/purchase-requests/all-requests',
        },
        {
          text: 'Vendor Payments',
          to: '/purchasing/vendor-payments',
        },
        {
          text: 'Preview',
          active: true,
        },
      ],
      resource: ['VendorPayment', 'MyVendorPayment', 'MyVendorPayment'],
      action: ['show', 'my-department-vp-show', 'my-vp-show'],
    },
  },
  {
    path: '/purchasing/purchase-requests/vendor-payment/single/:id/detail',
    name: 'purchasing-vendor-payment-single-view',
    component: () => import('@/views/purchase/vendor-payment/SingleInvoice.vue'),
    meta: {
      title: 'Vendor Payment Detail',
      pageTitle: 'Purchasing',
      navActiveLink: 'purchasing-vendor-payments-index',
      breadcrumb: [
        {
          text: 'Vendor Payments',
          to: '/purchasing/vendor-payments',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
      resource: ['VendorPayment', 'MyVendorPayment', 'MyVendorPayment'],
      action: ['show', 'my-department-vp-show', 'my-vp-show'],
    },
  },
]
