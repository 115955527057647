export default [
  {
    path: '/customers/customers',
    name: 'customers-customers-index',
    component: () => import('@/views/customer/customers/CustomerList.vue'),
    meta: {
      title: 'Customers',
      pageTitle: 'Customers',
      breadcrumb: [
        {
          text: 'Customers',
          active: true,
        },
      ],
      resource: 'Customer',
      action: 'read',
    },
  },
  {
    path: '/customers/customers/:id/show',
    name: 'customers-customers-show',
    component: () => import('@/views/customer/customers/CustomerDetail.vue'),
    meta: {
      title: 'Customer Detail',
      pageTitle: 'Customers',
      navActiveLink: 'customers-customers-index',
      breadcrumb: [
        {
          text: 'Customers',
          to: '/customers/customers',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
      resource: 'Customer',
      action: 'show',
    },
  },
  {
    path: '/customers/customers/:id/edit',
    name: 'customers-customers-edit',
    component: () => import('@/views/customer/customers/CustomerEdit.vue'),
    meta: {
      title: 'Update Customer',
      pageTitle: 'Customers',
      navActiveLink: 'customers-customers-index',
      breadcrumb: [
        {
          text: 'Customers',
          to: '/customers/customers',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
      resource: 'Customer',
      action: 'update',
    },
  },
  {
    path: '/customers/files',
    name: 'customers-files-index',
    component: () => import('@/views/customer/files/FileList.vue'),
    meta: {
      title: 'Files',
      pageTitle: 'Customers',
      breadcrumb: [
        {
          text: 'Files',
          active: true,
        },
      ],
      resource: 'File',
      action: 'read',
    },
  },
  {
    path: '/customers/files/:id/show',
    name: 'customers-files-show',
    component: () => import('@/views/customer/files/FileDetail.vue'),
    meta: {
      title: 'File Detail',
      pageTitle: 'Customers',
      navActiveLink: 'customers-files-index',
      breadcrumb: [
        {
          text: 'Files',
          to: '/customers/files',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
      resource: 'File',
      action: 'show',
    },
  },
  {
    path: '/customers/files/:id/nominees',
    name: 'customers-files-nominees',
    component: () => import('@/views/customer/files/Nominees.vue'),
    meta: {
      title: 'File Nominees',
      pageTitle: 'Customers',
      navActiveLink: 'customers-files-index',
      breadcrumb: [
        {
          text: 'Files',
          to: '/customers/files',
        },
        {
          text: 'Nominees',
          active: true,
        },
      ],
      resource: 'File',
      action: 'show',
    },
  },
  {
    path: '/customers/requests',
    name: 'app-customer-profile-update-index',
    component: () => import('@/views/customer/requests/List.vue'),
    meta: {
      title: 'Customer Requests List',
      pageTitle: 'Customers',
      breadcrumb: [
        {
          text: 'Customer Requests',
          active: true,
        },
      ],
      resource: 'CustomerRequest',
      action: 'read',
    },
  },
  {
    path: '/customers/requests/:id/view',
    name: 'app-customer-profile-update-view',
    component: () => import('@/views/customer/requests/Detail.vue'),
    meta: {
      title: 'Customer Requests Detail',
      pageTitle: 'Customers',
      navActiveLink: 'app-customer-profile-update-index',
      breadcrumb: [
        {
          text: 'Customer Requests',
          active: true,
        },
      ],
      resource: 'CustomerRequest',
      action: 'show',
    },
  },
  {
    path: '/customers/receipts',
    name: 'customers-receipts-index',
    component: () => import('@/views/customer/receipts/List.vue'),
    meta: {
      title: 'Receipts',
      pageTitle: 'Customers',
      breadcrumb: [
        {
          text: 'Payments/Receipts',
          active: true,
        },
      ],
      resource: 'Receipt',
      action: 'read',
    },
  },
  {
    path: '/customers/receipts/:id/show',
    name: 'customers-receipts-show',
    component: () => import('@/views/customer/receipts/Detail.vue'),
    meta: {
      title: 'Receipt Detail',
      pageTitle: 'Customers',
      navActiveLink: 'customers-receipts-index',
      breadcrumb: [
        {
          text: 'Payments/Receipts',
          to: '/customers/receipts',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
      resource: 'Receipt',
      action: 'show',
    },
  },
  {
    path: '/customers/invoices',
    name: 'customers-invoices-index',
    component: () => import('@/views/customer/invoices/List.vue'),
    meta: {
      title: 'Invoices',
      pageTitle: 'Customers',
      breadcrumb: [
        {
          text: 'Invoices',
          active: true,
        },
      ],
      resource: 'Invoice',
      action: 'read',
    },
  },
  {
    path: '/customers/invoices/:id/show',
    name: 'customers-invoices-show',
    component: () => import('@/views/customer/invoices/Detail.vue'),
    meta: {
      title: 'Invoice Detail',
      pageTitle: 'Customers',
      navActiveLink: 'customers-invoices-index',
      breadcrumb: [
        {
          text: 'Invoices',
          to: '/customers/invoices',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
      resource: 'Invoice',
      action: 'show',
    },
  },
  {
    path: '/customers/payments/register',
    name: 'customers-register-payment',
    component: () => import('@/views/customer/payment/RegisterPayment.vue'),
    meta: {
      title: 'Register Payment',
      pageTitle: 'Customers',
      navActiveLink: 'customers-receipts-index',
      breadcrumb: [
        {
          text: 'Payments/Receipts',
          to: '/customers/receipts',
        },
        {
          text: 'Register Payment',
          active: true,
        },
      ],
      resource: ['File', 'Invoice'],
      action: ['register-payment', 'register-payment'],
    },
  },
]
